<template>
  <div class="user--wrapper">
    <h2 class="page--title" v-if="Object.keys(model).length === 0"><u>Manage Users</u></h2>
    <div v-if="Object.keys(model).length !== 0" class="user">
      <div class="user--header">
        <h2 class="user--title">{{ model.title? model.title : 'No model seleceted'}}</h2>
        <!-- <button type="button" class="user--close" @click="setInsight">&#10005;</button> -->
      </div>
      <hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
      <div class="user--body">
        <form @submit.prevent="addEmail(email)">
          <div class="user--email">
            <label for="email" class="user--label">Search User By Email</label>
            <input type="email" name="email" id="email" v-model="email" @blur="onBlur($event)" placeholder="Search user by email" class="user--input" required />
            <div class="button--controls" v-show="email">
              <button type="submit">Add &nbsp; &plus;</button>
            </div>
          </div>
        </form>        
        <form v-show="!!emails.length" @submit.prevent="fetchUsers">
          <div class="email--content">
            <ul class="email--items">
              <li class="email--list" v-for="email in emails" :key="email"><span class="email">{{ email }}</span><span class="icon" @click="removeItem(emails, email);">&#10007;</span></li>
            </ul>
          </div>
          <div class="button--controls">
            <button type="submit">Search &nbsp; &#10003;</button>
          </div>
        </form>
        <form v-show="!!filterUsers.length">
          <div class="user--content">
            <ul class="user--items">
              <li class="user--list" :class="{exist: exist(user), selected: selected(user)}" v-for="user in filterUsers" :key="user.id" @click="selectUser(exist(user), user);"><span class="name">{{ user.email }}</span><span v-show="selected(user)" class="icon">&#10003;</span></li>
            </ul>
          </div>
          <div class="button--controls">
            <button type="button" @click="setInsight">Cancel &nbsp; &#10007;</button>
            <button type="button" @click="onSendRequest">Confirm &nbsp; &#10003;</button>
          </div>
        </form>
        <Modal @onResponse="onResponse" v-show="!!invites.length">
          <template v-slot:header>
            Send Invite
          </template>
          <template v-slot:body>
            <p>Would you like to invite the following to create an account on the this platform </p>
            <p> and subsequently participate in your survey and data gathering process?</p><br>
            <div class="invite--content">
              <ul class="invite--items">
                <li class="invite--list" v-for="invite in invites" :key="invite"><span class="invite">{{ invite }}</span><span class="icon" @click="removeItem(invites, invite);">&#10007;</span></li>
              </ul>
            </div>
            <!-- <p>P.S: This action is permanent, be sure that you appropriately maaking the right choice.</p> -->
          </template>
          <template v-slot:false>
            Cancel
          </template>
          <template v-slot:true>
            Send Invite
          </template>
        </Modal>
      </div>
    </div>
    <div v-else class="user" style="padding: 10px;">
			<div>Select a model to continue!</div>
		</div>
  </div>
</template>

<script>
// @ is an alias to /src
import Modal from "@/components/partials/Modal.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "User",
  components: {
    Modal,
  },
  data() {
    return {
      selections: [],
      invites: [],
      emails: [],
      email: "",
      keyword: "",
    };
  },
  watch: {
    invites() {
      if (this.invites.length) this.setOverlaying(true);
      else this.setOverlaying(false);
    },
  },
  computed: {
    ...mapGetters(["models", "model", "users", "user"]),
    filterUsers() {
      return !!this.users.length && this.users;
    },
  },
  methods: {
    ...mapActions([
      "setMessage",
      "setLoading",
      "setOverlaying",
      "getUsers",
      "sendRequest",
      "sendInvite",
    ]),
    onBlur(event) {
      event.target.style.borderColor = event.target.value
        ? "rgba(0,0,0, 1)"
        : "rgba(255, 0, 0, 1)";
    },
    setInsight() {
      /**
       * Emits 'setInsight' to dashboard
       * @param
       * @return
       */
      this.$emit("setInsight", "isInsight");
    },
    addEmail(email) {
      if (this.model.users.includes(email))
        return this.setMessage({
          text: `User already exist!`,
          status: false,
        });
      if (this.users.some((user) => user.email === email))
        return this.setMessage({
          text: `User already exist!`,
          status: false,
        });
      if (this.emails.includes(this.email))
        return this.setMessage({
          text: `Email already selected!`,
          status: false,
        });
      this.emails.push(this.email);
      this.email = "";
    },
    removeItem(items, item) {
      items.splice(items.indexOf(item), 1);
    },
    async fetchUsers() {
      if (!this.emails.length)
        return this.setMessage({
          text: `No email entered!`,
          status: false,
        });
      this.setLoading(true);
      const data = await this.getUsers(this.emails);
      if (data.length) {
        this.invites = await this.emails.filter(
          (email) => !data.some((item) => item.email === email)
        );
      } else this.invites = await this.emails;
      this.emails = [];
      this.setLoading(false);
    },
    exist(item) {
      /**
       * Return true if user has already joined
       * @param {object} item
       * @return {boolean}
       */
      return (
        !!this.model.users.length &&
        this.model.users.some((user) => user === item.email)
      );
    },
    selected(item) {
      /**
       * Return true if user has already been selected
       * @param {object} item
       * @return {boolean}
       */
      return (
        !!this.selections.length &&
        this.selections.some((user) => user.email === item.email)
      );
    },
    selectUser(user, item) {
      // Adds user to selections or otherwise
      if (user)
        return this.setMessage({
          text: `User already exist!`,
          status: false,
        });
      if (this.selections.some((user) => user === item))
        return this.selections.splice(this.selections.indexOf(item), 1);
      else this.selections.push(item);
    },
    async onResponse(response) {
      // Send invite to user to join platform
      if (response) await this.sendInvite({ invites: this.invites });
      this.invites = [];
    },
    async onSendRequest() {
      // Sends request to join survey
      if (!this.selections.length)
        return this.setMessage({
          text: `No user selected!`,
          status: false,
        });
      this.setLoading(true);
      await this.sendRequest({
        selections: this.selections,
        model: { id: this.model.id, title: this.model.title },
      });
      this.selections = [];
      this.setLoading(false);
    },
  },
};
</script>

<style scoped>
/* user */
.user--wrapper {
  padding: 0.2rem;
}
.page--title {
  text-align: center;
  margin: 10px 0 5px 0;
}
.user {
  /*padding: 10px;*/
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  /* box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px; */
}
.user--header {
  display: flex;
}
.user--title {
  text-align: center;
  flex: auto;
  margin: 10px 0 5px 0;
}
.user--header button {
  background-color: rgba(209, 213, 219, 1);
  font-weight: 900;
  font-size: larger;
  padding: 0.75rem 1rem;
  border-radius: 0;
}
.user--body {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
  padding: 0 5px;
}
.user--email {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}
.user--label {
  font-size: 14px;
  color: rgb(61, 79, 88);
  position: relative;
  height: 16px;
  /*width: 36px;*/
  text-align: left;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.02em;
}
.user--input {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(229, 231, 235, 1);
  border-radius: 4px;
  height: 52px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  margin-top: 5px;
  padding-left: 12px;
  padding-right: 12px;
  transition: border-color 150ms ease-in-out 0s;
}
.email--content,
.user--content,
.invite--content {
  min-height: 200px;
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
  border: 2px solid #374151;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
.email--items,
.invite--items {
  display: flex;
  padding: 0.5rem;
  flex-wrap: wrap;
}
.email--list,
.invite--list {
  display: flex;
  list-style-type: none;
  background-color: rgba(229, 231, 235, 1);
  border-bottom: 1px solid rgba(209, 213, 219, 1);
  border-radius: 10px;
  text-align: left;
  padding-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.email--list:hover,
.invite--list:hover {
  background-color: rgba(209, 213, 219, 1);
}
.email--list .email,
.invite--list .invite {
  flex: auto;
  padding-right: 0.75rem;
  /* padding-left: 0.75rem; */
  font-size: smaller;
}
.email--list .icon,
.invite--list .icon {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  cursor: pointer;
}
/* .user--content {
  min-height: 400px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
  border: 2px solid #374151;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
} */
.user--list {
  display: flex;
  list-style-type: none;
  background-color: rgba(229, 231, 235, 1);
  border-bottom: 1px solid rgba(209, 213, 219, 1);
  text-align: left;
  padding-left: 4px;
}
.user--list:hover {
  list-style-type: none;
  background-color: rgba(209, 213, 219, 1);
  border-bottom: 1px solid rgba(229, 231, 235, 1);
}
.user--list.exist {
  background-color: #078c50;
  color: #000;
}
.user--list.selected {
  background-color: #e6cf4d;
  color: #000;
}
.user--list .name {
  flex: auto;
}
.user--list .icon {
  padding-right: 1rem;
}
.user--footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
  padding: 0 5px;
  padding-bottom: 5px;
}
.user--role {
  text-align: left;
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}
.user--role > select {
  width: 100%;
  padding: 0.3rem;
  border-bottom: 2px solid #374151;
}
.button--controls {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  padding-bottom: 3rem;
}
.button--controls button {
  cursor: pointer;
  padding: 0.2rem 0.5rem;
  background-color: rgba(209, 213, 219, 1);
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
  transition: all 1s ease-out;
}
.button--controls button:hover {
  background-color: rgba(229, 231, 235, 1);
}
.button--controls button:first-child {
  margin-right: 1rem !important;
}
.button--controls button:last-child {
  margin-right: 0rem !important;
}
.modal--action {
  width: 100%;
  height: 100%;
  padding-top: 10px;
}
/* mini screen */
@media screen and (min-width: 481px) {
  .user--wrapper {
    padding: 0.5rem;
  }
}
/* max screen */
@media screen and (min-width: 981px) {
  .user--wrapper {
    padding: 1rem;
  }
}
</style>