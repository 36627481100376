<template>
	<div class="insight--wrapper">
		<div>
			<h1 class="page--title"><u>{{record.title? record.title : 'Insight'}}</u></h1>
		</div>
    <div class="" v-if="!!records.length">
      <button class="filter--button" @click="onFilter">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40" viewBox="0 0 100 100">
          <g transform="translate(10,70) scale(0.05,-0.05)">
            <path fill="#374151" glyph-name="sliders" unicode="" d="M196 64v-71h-196v71h196z m197 72q14 0 25-11t11-25v-143q0-14-11-25t-25-11h-143q-14 0-25 11t-11 25v143q0 15 11 25t25 11h143z m89 214v-71h-482v71h482z m-357 286v-72h-125v72h125z m732-572v-71h-411v71h411z m-536 643q15 0 26-10t10-26v-142q0-15-10-26t-26-10h-142q-15 0-26 10t-10 26v142q0 15 10 26t26 10h142z m358-286q14 0 25-10t10-25v-143q0-15-10-25t-25-11h-143q-15 0-25 11t-11 25v143q0 14 11 25t25 10h143z m178-71v-71h-125v71h125z m0 286v-72h-482v72h482z" horiz-adv-x="857.1"></path>
          </g>
        </svg>
        <span>Filter</span>
      </button>
    </div>
    <div class="insight" v-if="!!records.length" ref="insight">
      <div class="container">
        <div class="barContainer" id="barContainer" ref="barContainer">
          <svg class="barChart" id="barChart" ref="barChart" :viewBox="`0 0 ${width} ${height}`" :width="`${width}`" :height="`${height - margin.top - margin.bottom}`">
            <g class="bars" id="bars" ref="bars">
              <rect :x="bar.x" :y="bar.y" :title="bar.title" class="rect" :fill="bar.color" :height="bar.height" :width="bar.width" v-for="(bar, i) in bars" :key="`${bar.title}_${i}`">
                <title>{{`${bar.name}: ${bar.value}`}}</title>
              </rect>
            </g>
            <g class="x-axis" v-axis="getAxisX(records)" :transform="`translate(0,${height - margin.bottom})`" fill="none" font-size="12px" font-family="sans-serif" text-anchor="middle"></g>
            <g class="y-axis" v-axis="getAxisY(records)" :transform="`translate(${margin.left}, 0)`" fill="none" font-size="20px" font-family="sans-serif" text-anchor="end"></g>
          </svg>
        </div>
        <div class="pieContainer" id="pieContainer" ref="pieContainer">
          <svg class="pieChart" id="pieChart" ref="pieChart" :viewBox="`0 0 ${width} ${height}`" :width="`${width}`" :height="`${height}`">
            <g class="arcs" id="arcs" ref="arcs" :transform="`translate(${width / 1.7}, ${height / 2.4})`">
              <g class="arc" v-for="(pie, index) in pies" :key="`${pie.data.name}_${index}`">
                <title>{{`${pie.data.name}: ${pie.value}`}}</title>
                <path :d="pie.path" :fill="pie.color"></path>
                <text fill="white" :transform="`translate(${pie.centroid})`" text-anchor="middle">
    <!--               <tspan x="0" font-size="initial">{{pie.data.name}}</tspan> -->
                  <tspan x="0" font-size="1rem" dy="1.3em">{{pie.value}}</tspan>
                </text>
              </g>
            </g>
            <g class="legends" :transform="`translate(${margin.left *  0.5}, ${margin.left * 0.5})`">
              <g class="legend" v-for="(legend, index) in legends" :key="`${legend.yRect}_${index}`">
                <rect :y="legend.yRect" :width="legend.widthRect" :height="legend.heightRect" :fill="legend.color" stroke="grey" style="stroke-width: 1px;"></rect>
                <text :x="legend.xText" :y="legend.yText" style="font-family: sans-serif; font-size: 18px;">{{legend.name}}</text>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <Modal @onResponse="onResponse" v-show="isFilter">
        <template v-slot:header>
          Filter Records
        </template>
        <template v-slot:body>
          <p>Select items to be exported:</p><br>
          <div class="keys--content">
            <ul class="keys--items">
              <li class="keys--list" v-for="(key, i) in keys" :key="i">
                <label :for="key">
                  <input type="checkbox" :value="key" :id="key" v-model="selections" />
                  <span> {{key}}</span>
                </label>
              </li>
            </ul>
          </div>
        </template>
        <template v-slot:false>
          Cancel
        </template>
        <template v-slot:true>
          Export Data
        </template>
      </Modal>
    </div>
    <div v-else-if="record.title" class="insight--item" style="padding: 10px;">
			<div>Selected model has no data!</div>
		</div>
		<div v-else class="insight--item" style="padding: 10px;">
			<div>Select a model to continue!</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Modal from "@/components/partials/Modal.vue";
import * as d3 from "d3";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Insight",
  components: {
    Modal,
  },
  data() {
    return {
      isFilter: false,
      selections: [],
      header: "",
      records: [],
      width: 400,
      height: 500,
      legendOffset: 18,
      pies: [],
      legends: [],
      bars: [],
    };
  },
  watch: {
    // record: {
    //   handler: async function (val, oldVal) {
    //     console.log("watch 1", "newval: ", val, "   oldVal:", oldVal);
    //   },
    //   deep: true,
    // },
    isFilter() {
      this.setOverlaying(this.isFilter);
    },
  },
  computed: {
    ...mapGetters(["record", "keys"]),
    margin() {
      return {
        top: this.height * 0.05,
        bottom: this.height * 0.05,
        left: this.width * 0.05,
        right: this.width * 0.05,
      };
    },
    radius() {
      return (Math.min(this.width, this.height) / 2) * 0.8;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResizeScreen);
    this.getRecords();
  },
  mounted() {
    this.setOverlaying(this.isFilter);
    this.drawBarChart(this.records);
    this.drawPieChart(this.records);
  },
  methods: {
    ...mapActions(["setMessage", "setLoading", "setOverlaying", "setKeys"]),
    handleResizeScreen() {
      this.drawBarChart(this.records);
      this.drawPieChart(this.records);
    },
    drawBarChart(data) {
      if (!data.length) return;
      this.width = this.$refs.barContainer
        ? this.$refs.barContainer.offsetWidth
        : this.width;
      this.bars = [];
      // TODO: Get bars
      for (let bar of this.getBar(data)) {
        if (!bar.status) return;
        this.bars.push({
          ...bar,
          color: this.getColors(data)(bar.value),
        });
      }
    },
    drawPieChart(data) {
      if (!data.length) return;
      this.width = this.$refs.pieContainer
        ? this.$refs.pieContainer.offsetWidth
        : this.width;
      this.pies = [];
      this.legends = [];
      // TODO: Get pies
      for (let pie of this.getPie(data)) {
        this.pies.push({
          ...pie,
          path: this.getArc(this.radius)(pie),
          // color: this.getColors(data)(pie.data.name),
          color: this.getColors(data)(pie.data.value),
          centroid: this.getLabel(this.radius).centroid(pie),
        });
      }
      // TODO: Get legends
      for (let legend of this.getLegend(data, this.legendOffset)) {
        this.legends.push({
          ...legend,
          // color: this.getColors(data)(legend.name),
          color: this.getColors(data)(legend.value),
        });
      }
    },
    getPie(data) {
      // TODO:
      return (
        d3
          .pie()
          // .sort(null)
          .sort((a, b) => d3.descending(a.value, b.value))
          .value((d) => d.value)(data)
      );
    },
    getArc(radius) {
      return d3.arc().innerRadius(0).outerRadius(radius);
    },
    getLabel(radius) {
      return d3
        .arc()
        .innerRadius(radius)
        .outerRadius(radius / 2);
    },
    getColors(data) {
      // TODO: Get color bands
      return (
        d3
          .scaleOrdinal()
          // .domain(data.map(d => d.name))
          // .range(d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.1), data.length).reverse());
          .domain(
            data
              .sort((a, b) => d3.descending(a.value, b.value))
              .map((d) => d.value)
          )
          .range(
            d3
              .quantize((t) => d3.interpolateGreens(t * 0.8 + 0.1), data.length)
              .reverse()
          )
      );
    },
    getLegend(data, offset) {
      // TODO:
      return data.map((d, i) => ({
        yRect: offset * i * 1.8,
        widthRect: offset,
        heightRect: offset,
        xText: offset * 1.2,
        yText: offset * i * 1.8 + offset,
        name: d.name,
        value: d.value,
      }));
    },
    getBar(data) {
      // TODO:
      return data
        .sort((a, b) => d3.descending(a.value, b.value))
        .map((d, i) => ({
          x: this.getScaleX(data)(i),
          y: this.getScaleY(data)(d.value),
          title: d.value,
          value: d.value,
          status: d.status,
          name: d.name,
          height: this.getScaleY(data)(0) - this.getScaleY(data)(d.value),
          width: this.getScaleX(data).bandwidth(),
        }));
    },
    getMaxValue(data) {
      // TODO:
      return d3.max(data, (d) => d.value);
    },
    getRange(data) {
      // TODO:
      return d3.range(data.length);
    },
    getScaleX(data) {
      // TODO:
      return d3
        .scaleBand()
        .domain(this.getRange(data))
        .range([this.width * 0.05, this.width - this.width * 0.05])
        .padding(0.1);
    },
    getScaleY(data) {
      // TODO:
      return d3
        .scaleLinear()
        .domain([0, this.getMaxValue(data)])
        .range([this.height - this.height * 0.05, this.height * 0.05]);
    },
    getAxisX(data) {
      // TODO:
      return d3
        .axisBottom(this.getScaleX(data))
        .tickFormat((i) => data[i].name);
      // return d3.axisBottom().scale(this.getScaleX(data)).tickFormat(d3.timeFormat("%Y/%m"));
    },
    getAxisY(data) {
      // TODO:
      return d3
        .axisLeft(this.getScaleY(data))
        .ticks(null, data.format)
        .tickFormat((value) => d3.format("~s")(value));
      // return d3.axisLeft().scale(this.getScaleY(data)).tickFormat(value => d3.format("~s")(value));
    },
    getRecords() {
      let items = {};
      let keys = [];
      this.records = [];
      if (Object.entries(this.record).length === 0) return;
      this.record.records.forEach((record) => {
        for (let rec in record) {
          if (record[rec] !== null)
            items[rec] = items[rec] ? items[rec] + 1 : 1;
          if (!keys.includes(rec)) keys.push(rec);
        }
      });
      this.setKeys(keys);
      for (let item in items) {
        this.records.push({
          name: item,
          value: items[item],
          status: true,
        });
      }
      return this.records;
    },
    onFilter() {
      this.isFilter = true;
      this.setOverlaying(this.isFilter);
    },
    async onResponse(response) {
      // TODO: Export data
      this.isFilter = false;
      this.setOverlaying(this.isFilter);
      if (!response) return (this.selections = []);
      this.setLoading(true);
      this.exportcsv(this.record.records, this.record.title);
      this.selections = [];
      this.setLoading(false);
      return;
    },
    converter(data) {
      // TODO: Use filter to append values
      this.header = this.selections.join();
      let output = "";
      data.forEach((element) => {
        var line = "";
        for (var index in this.selections) {
          if (line != "") line += ",";
          if (element[this.selections[index]] === undefined) line += null;
          else if (element[this.selections[index]] === "") line += null;
          else line += element[this.selections[index]];
        }
        output += line + "\r\n";
      });
      return `${this.header}\r\n${output}`;
    },
    exportcsv(data, file) {
      // TODO: Generate .csv with record title
      var csv = this.converter(data);
      var exportedFilenmae = file
        ? file.split(" ").join("_") + ".csv"
        : "export.csv";
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
  },
  directives: {
    axis(el, binding) {
      d3.select(el).call(binding.value);
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResizeScreen);
  },
};
</script>

<style scoped>
/* insight */
.insight--wrapper {
  display: flex;
  flex-direction: column;
  padding: 0.2rem;
}
.page--title {
  text-align: center;
  margin: 10px 0 5px 0;
}
.insight {
  /* padding: 10px; */
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  /* box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px; */
}
.insight--item {
  padding: 10px;
  width: 100%;
  height: 3rem;
  margin-right: auto;
  margin-left: auto;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
.filter--button {
  display: flex;
  height: 3rem;
  border-radius: 0.25rem;
  cursor: pointer;
  align-items: center;
  background-color: rgba(229, 231, 235, 1);
  margin-bottom: 1rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
.filter--button:hover {
  background-color: rgba(209, 213, 219, 1);
}
.keys--content {
  text-align: left;
  padding-left: 2rem;
}
.keys--items {
  list-style-type: none;
}
.container {
  display: flex;
  flex-direction: column;
}
.barContainer,
.pieContainer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0%;
  margin-bottom: 3rem;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
svg {
  width: 100%;
}
.rect:hover,
.arc:hover,
.legend:hover {
  opacity: 0.5;
  border: 1px solid #000;
}
.chart {
  width: 100%;
}
.chart svg {
  display: block;
  margin: auto;
}
.rect:hover,
.arc:hover {
  opacity: 0.5;
}
/* mini screen */
@media screen and (min-width: 481px) {
  .insight--wrapper {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
/* max screen */
@media screen and (min-width: 981px) {
}
</style>