<template>
	<div>
		<div class="designer">
			<!--<section>-->
			<!--	<h2 class="page--title"><u>Customize Fields</u></h2>-->
			<!--</section>-->
			<section class="designer--container" style="">
				<div class="designer--sample"><h4 class="page--title"><u>Samples</u></h4>
					<div class="sample" v-for="(sample, index) in samples" :key="index" draggable="true" @dragstart='drag($event, sample)' title="Drag element to the right">
						<p style="padding: 5px;" :id="sample.id">{{sample.input}}</p>
					</div>
					<div class="sample--details" v-if="!!items.length && Object.entries(active).length !== 0"><h4 class="page--title"><u>Panel</u></h4>
						<form>
							<div class="form--item">
								<label for="label" class="required">Label: </label>
								<input type="text" class="form--input" name="label" placeholder="Enter default label" v-model="active.label">
							</div>
							<div class="form--item">
								<label for="name" class="required">Name: </label>
								<input type="text" class="form--input" name="name" placeholder="Enter default name" v-model="active.name">
							</div>
							<div class="form--item" v-if="!isplaceholder">
								<label for="placeholder" class="required">Placeholder: </label>
								<input type="text" class="form--input" name="placeholder" placeholder="Enter default placeholder" v-model="active.placeholder"/>
							</div>
							<!-- <div class="form--item" v-if="active.type !== 'file'">
								<label for="value" class="required">Default Value: </label>
								<input type="text" class="form--input" name="value" placeholder="Enter default value" v-model="active.value"/>
							</div> -->
							<div class="form--item">
								<label for="required">Input Required: </label>
								<input type="checkbox" name="required" id="required" v-model="active.required" checked>
							</div>
							<div class="form--item" v-if="active.options">
								<label for="multiple">Multiple Selection: </label>
								<input type="checkbox" name="multiple" id="multiple" v-model="active.multiple" checked>
							</div>
							<div class="form--item" v-if="active.options">
								<label for="options">Options: </label>
								<div style="display: flex; margin: .5rem 0;" v-for="(option, counter) in active.options" :key="counter">
									<input style="flex: auto; margin-right: 5px;" type="text" class="form--item--options" name="options" v-model="option.name">
									<button style="padding: 0 1rem; font-size: larger; background-color: rgba(209,213,219, 1); box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 1px;" type="button" @click="deleteOption(counter)">&times;</button>
								</div>
								<button style="margin-top: .5rem; padding: 0 1rem; font-size: larger; background-color: rgba(209,213,219, 1); box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 1px;" type="button" @click="addOption($event)">&plus;</button>
							</div>
							<div>
								<button type="button" class="details--button" @click="save">Save</button>
							</div>
						</form>
					</div>
				</div>
				<div class="designer--model" @drop='drop($event)' @dragover.prevent @dragenter.prevent><h4 class="page--title"><u>Model</u></h4>
					<form>
						<div v-if="!!validations.length" class="validations">
							<ul style="text-align: left; list-style-type: disc;">
								<li style="text-transform: capitalize;" v-for="(validation, index) in validations" :key="index">{{validation}}</li>
							</ul>
						</div>
						<div class="model--title">
							<label for="title">Title: </label>
              <span :class="{maxLimit: maxLimit}">{{maxlength - title.length}}</span>
							<input type="text" name="title" id="title" v-model="title" placeholder="Enter model title" @blur="onBlur($event)" class="form--input" :maxlength="maxlength">
						</div>
						<div class="model" v-for="(model, index) in items" :key="index" draggable @dragstart='drag($event, model.id)' @click.stop.prevent="active = model;">
							<label :for="model.name">{{model.label}}:</label>
              <span class="arrows">
                <button type="button" @click.stop="remove(index)">&#10006;</button>
                <button type="button" @click="up(index)">&#9650;</button>
                <button type="button" @click="down(index)">&#9660;</button>
              </span>
							<select v-if="model.type === 'select'" :name="model.name" style="width: 100%;">
								<option>--</option>
								<option value='' v-for="(option, counter) in model.options" :key="counter">{{option.name}}</option>
							</select>
							<input v-else :type="model.type" :name="model.name" :placeholder="model.placeholder" v-model="model.placeholder" class="form--input" :required="model.required" style="width: 100%;">
						</div>
						<div v-if="!!items.length">
							<button v-if="isEditing" type="button" class="submit--container" @click="updateModel">Update Model</button>
							<button v-else type="button" class="submit--container" @click="addModel">Add Model</button>
						</div>
					</form>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
export default {
  name: "Designer",
  props: ["isEditing"],
  data() {
    return {
      maxlength: 36,
      validations: [],
      title: "",
      active: {},
      samples: [
        {
          id: null,
          name: null,
          type: "text",
          placeholder: null,
          required: null,
          ariaRequired: true,
          ariaInvalid: false,
          label: "Text",
          input: "Text",
          value: null,
          tag: "text",
        },
        {
          id: null,
          name: null,
          type: "email",
          placeholder: null,
          required: null,
          ariaRequired: true,
          ariaInvalid: false,
          label: "Email",
          input: "Email",
          value: null,
          tag: "email",
        },
        {
          id: null,
          name: null,
          type: "file",
          placeholder: null,
          required: null,
          ariaRequired: true,
          ariaInvalid: false,
          label: "File",
          input: "File",
          value: null,
          tag: "file",
        },
        {
          id: null,
          name: null,
          type: "number",
          placeholder: null,
          required: null,
          ariaRequired: true,
          ariaInvalid: false,
          label: "Number",
          input: "Number",
          value: null,
          tag: "number",
        },
        {
          id: null,
          name: null,
          type: "file",
          placeholder: null,
          required: null,
          ariaRequired: true,
          ariaInvalid: false,
          label: "Image",
          input: "Image",
          value: null,
          tag: "image",
        },
        {
          id: null,
          name: null,
          type: "date",
          placeholder: null,
          required: null,
          ariaRequired: true,
          ariaInvalid: false,
          label: "Date",
          input: "Date",
          value: null,
          tag: "date",
        },
        {
          id: null,
          name: null,
          type: "time",
          placeholder: null,
          required: null,
          ariaRequired: true,
          ariaInvalid: false,
          label: "Time",
          input: "Time",
          value: null,
          tag: "time",
        },
        {
          id: null,
          name: null,
          type: "tel",
          placeholder: null,
          required: null,
          ariaRequired: true,
          ariaInvalid: false,
          label: "Tel",
          input: "Tel",
          value: null,
          tag: "tel",
        },
        {
          id: null,
          name: null,
          type: "select",
          placeholder: null,
          required: null,
          ariaRequired: true,
          ariaInvalid: false,
          label: "Select",
          input: "Select",
          value: null,
          tag: "select",
        },
        // {
        //   id: null,
        //   name: null,
        //   type: "checkbox",
        //   placeholder: null,
        //   required: null,
        //   ariaRequired: true,
        //   ariaInvalid: false,
        //   label: "Checkbox",
        //   input: "Checkbox",
        //   value: null,
        //   tag: "checkbox",
        // },
        // {
        //   id: null,
        //   name: null,
        //   type: "radio",
        //   placeholder: null,
        //   required: null,
        //   ariaRequired: true,
        //   ariaInvalid: false,
        //   label: "Radio",
        //   input: "Radio",
        //   value: null,
        //   tag: "radio",
        // },
      ],
      items: [],
      item: {},
    };
  },
  computed: {
    ...mapGetters(["models", "model", "profile"]),
    maxLimit() {
      return this.title.length > 25;
    },
    isplaceholder() {
      return this.active.type.match("file|date|time|number") ? true : false;
    },
  },
  mounted() {
    this.samples.filter((sample) => {
      return (sample.id = Math.floor(Math.random() * 1000000000000 + 1));
    });
    if (this.isEditing) {
      this.items = this.model.items;
      this.title = this.model.title;
    }
  },
  methods: {
    onBlur(event) {
      event.target.style.borderColor = event.target.value
        ? "rgba(229,231,235, 1)"
        : "rgba(255, 0, 0, 1)";
    },
    addOption() {
      var check = Object.values(
        this.active.options[this.active.options.length - 1]
      ).filter((value) => value !== "");
      if (
        Object.values(this.active.options[this.active.options.length - 1])
          .length !== check.length
      ) {
        return;
      }
      this.active.options.push({
        name: "",
      });
    },
    deleteOption(counter) {
      if (this.active.options.length === 1) {
        return;
      }
      this.active.options.splice(counter, 1);
    },
    remove(index) {
      /**
       * Find and remove item at index in the array
       * Check if items is empty and return active
       */
      this.items.splice(index, 1);
      this.active = {};
      // event.stopPropagation();
    },
    up(index) {
      /**
       * Check if start of items
       * Swap items
       *	Assign active item
       */
      if (index === 0) {
        return;
      }
      [this.items[index - 1], this.items[index]] = [
        this.items[index],
        this.items[index - 1],
      ];
      this.items = [...this.items];
      this.active = this.items[index];
    },
    down(index) {
      /**
       * Check if end of items
       * Swap items
       *	Assign active item
       */
      if (index === this.items.length - 1) {
        return;
      }
      [this.items[index], this.items[index + 1]] = [
        this.items[index + 1],
        this.items[index],
      ];
      this.items = [...this.items];
      this.active = this.items[index];
    },
    drag: (evt, sample) => {
      sample.id = Date.now();
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("SampleID", sample.id);
    },
    drop(evt) {
      const sampleID = evt.dataTransfer.getData("sampleID");
      const sample = this.samples.find((sample) => sample.id == sampleID);
      const item = {
        id: sample.id,
        name: sample.name,
        value: sample.value,
        type: sample.type,
        placeholder: sample.placeholder,
        required: sample.required,
        ariaRequired: sample.ariaRequired,
        ariaInvalid: sample.ariaInvalid,
        label: sample.label,
        input: sample.input,
        tag: sample.tag,
      };
      if (item.type === "file") {
        item.url = "";
      }
      if (item.type === "select") {
        item.options = [{ name: "" }];
        item.multiple = false;
      }

      // 	this.items.push(item);
      // 	this.active = this.items[this.items.length-1];
      this.item = item;
      this.active = this.item;
      this.items.push(this.item);
      // this.$store.dispatch('addItems', this.item);

      // Dispatch item and add to models
      // this.$store.dispatch('addItems', {
      //   items: this.items
      // })
      // .then(() => {
      //   this.active = this.items[this.items.length-1];
      // })
      const index = this.samples.findIndex((sample) => sample.id == sampleID);
      if (index !== -1) {
        // sample.id = null;
        sample.id = Math.floor(Math.random() * 1000000000000 + 1);
        this.samples.splice(index, 1, sample);
      }
    },
    save() {
      // this.$store.dispatch('addItems', {
      //   item: this.item
      // });
      this.active = {};
    },
    validate() {
      // Empty Errors container
      this.validations = [];
      // Check If Facility Name Is Empty
      if (!this.title) {
        this.validations.push("Title is required!");
      }
      // Clear error messages on the page
      setTimeout(() => (this.validations = []), 5000);
      // If No Errors Return True
      if (this.validations.length) {
        return false;
      }
      return true;
    },
    addModel() {
      let validate = this.validate();
      if (!validate) {
        return;
      }
      const items = [...this.items];
      this.$store.dispatch("addModel", {
        items: items,
        title: this.title,
        ownerId: this.profile.uid,
        users: [this.profile.email],
        owners: [this.profile.email],
      });
      this.items = [];
      this.item = {};
      this.title = "";
      this.active = {};
    },
    updateModel() {
      const items = [...this.items];
      this.$store.dispatch("updateModel", {
        id: this.model.id,
        items: items,
        title: this.title,
        ownerId: this.model.ownerId,
        owners: this.model.owners,
        users: this.model.users,
      });
      this.items = [];
      this.item = {};
      this.title = "";
      this.active = {};
      this.$emit("cancelEdit", false);
    },
  },
  beforeDestroy() {
    this.$emit("cancelEdit", false);
  },
};
</script>

<style scoped>
/* designer */
.designer {
  padding: 0 0.5rem;
  /* background-color: #f5f5f5; */
}
.page--title {
  text-align: center;
  margin: 10px 0 10px 0;
}
.validations {
  display: flex;
  justify-content: center;
  color: #ff0000;
  padding: 0.5rem;
  border-radius: 5px;
}
.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
}
.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}
.designer--container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2rem;
}
.designer--sample {
  min-height: 25px;
  width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.sample {
  background-color: rgba(229, 231, 235, 1);
  margin: 15px 5px;
  padding: 5px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.sample:hover {
  background-color: rgba(209, 213, 219, 1);
}
.sample--details {
  background-color: rgba(243, 244, 246, 1);
  margin-top: 5rem !important;
  margin: 5px;
  padding: 5px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.details--button {
  margin: 10px 0;
  padding: 0.2rem 0.5rem;
  background-color: rgba(209, 213, 219, 1);
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
.form--item {
  display: inline-block;
  margin: 0.5rem 0;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  width: 100%;
  position: relative;
  text-align: left;
}
.form--item div {
  display: flex;
}
.form--item div input {
  flex: auto;
  margin-right: 5px;
}
.form--item div button {
  padding: 0 1rem;
  font-size: larger;
  background-color: rgba(209, 213, 219, 1);
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
.form--item button {
  padding: 0 1rem;
  font-size: larger;
  background-color: rgba(209, 213, 219, 1);
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
.required:after {
  color: #ff0000;
  content: "*";
  font-weight: 500;
  padding-left: 0.5rem;
}
.form--item input[type="checkbox"] {
  margin: 0px;
  padding: 0;
  height: 1rem;
  width: 1rem;
  cursor: pointer;
}
.form--input {
  width: 100%;
  line-height: 2;
  padding-left: 0.5rem;
  border-bottom: 2px solid #374151;
}
.designer--model {
  min-height: 25px;
  width: 100%;
  margin-top: 5rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.model--title {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-color: rgba(229, 231, 235, 1);
  text-align: left;
  margin: 15px 5px;
  padding: 5px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.model--title label {
  flex: auto;
}
.model--title input {
  width: 100%;
}
.maxLimit {
  color: #e0245e;
}
.model {
  position: relative;
  background-color: rgba(229, 231, 235, 1);
  text-align: left;
  margin: 15px 5px;
  padding: 5px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  align-items: center;
  display: flex;
  flex-wrap: wrap !important;
}
.model label {
  flex: auto;
}
.model span {
  display: flex;
  right: 5px;
}
.model span button {
  padding: 0 0.5rem;
  margin-left: 0.5rem;
  border: 1px solid;
  cursor: pointer;
  background-color: transparent;
}
.model input,
.model select,
.model textarea {
  width: 100%;
  flex: auto;
  order: 2 !important;
}
.submit--container {
  margin: 10px 0;
  padding: 0.2rem 0.5rem;
  background-color: rgba(209, 213, 219, 1);
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
/* Wider screen */
@media screen and (min-width: 981px) {
  .designer {
    /*width: 70%;*/
    margin: 0 auto;
  }
  .designer--container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .designer--sample {
    min-height: 25px;
    margin-right: 0.5rem;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
  .designer--model {
    min-height: 25px;
    margin-left: 0.5rem;
    margin-top: 0;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
}
</style>