<template>
	<div class="account">
		<div class="account--content">         
			<form class="account--form" @submit.prevent="updatePassword">
				<div class="account--header">
					<h2 class="account--title">Update your password</h2>
				</div>
				<div class="account--item">
					<label class="account--label" for="password">Old Password: </label>
					<input class="account--input" type="password" name="password" id="password" v-model="passwords.password" @blur="onBlur($event)" placeholder="Enter old password" aria-required="true" aria-invalid="false" required/>
				</div>
				<div class="account--item">
					<label class="account--label" for="new_password">New Password: </label>
					<input class="account--input" type="password" name="new_password" id="new_password" v-model="passwords.new_password" @blur="onBlur($event)" placeholder="Enter new password" aria-required="true" aria-invalid="false" required/>
				</div>
				<div class="account--item">
					<label class="account--label" for="confirm_password">Confirm New Password: </label>
					<input class="account--input" type="password" name="confirm_password" id="confirm_password" v-model="passwords.confirm_password" @blur="onBlur($event)" placeholder="Re-type new password" aria-required="true" aria-invalid="false" required/>
				</div>
				<div class="account--item">
					<button class="account--button" :class="{isPasswordValid: isPasswordValid}" :disabled="!isPasswordValid" type="submit">Update Password</button>
				</div>
			</form>
		</div>
		<div class="account--content">         
			<form class="account--form" @submit.prevent="updateEmail">
				<div class="account--header">
					<h2 class="account--title">Update your email</h2>
				</div>
				<div class="account--item">
					<label class="account--label" for="email">New Email: </label>
					<input class="account--input" type="email" name="email" id="email" v-model="user.email" @blur="onBlur($event)" placeholder="Enter your email" aria-required="true" aria-invalid="false" required/>
				</div>
				<div class="account--item">
					<button class="account--button" :class="{isEmailValid: isEmailValid}" :disabled="!isEmailValid" type="submit">Update Email</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Account",
  components: {},
  data() {
    return {
      user: {
        email: "",
      },
      passwords: {
        password: "",
        new_password: "",
        confirm_password: "",
      },
    };
  },
  computed: {
    isPasswordValid() {
      return (
        this.passwords.password !== "" &&
        this.passwords.new_password !== "" &&
        this.passwords.confirm_password !== ""
      );
    },
    isEmailValid() {
      return this.user.email !== "";
    },
  },
  methods: {
    onBlur(event) {
      event.target.style.borderColor = event.target.value
        ? "rgba(229,231,235, 1)"
        : "rgba(255, 0, 0, 1)";
    },
    updateEmail() {
      // console.log(this.user);
    },
    updatePassword() {
      // console.log(this.passwords);
    },
  },
};
</script>

<style scoped>
/* account */
.account {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.account--form {
  width: 100%;
  margin: 50px auto;
}
.account--title {
  text-align: center;
  margin: 2rem 0;
}
.account--item {
  display: flex;
  flex-direction: column;
  border: none;
  margin: 0px 0px 20px;
  padding: 0px;
}
.account--label {
  font-size: 14px;
  color: rgb(61, 79, 88);
  position: relative;
  height: 16px;
  /*width: 36px;*/
  text-align: left;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.02em;
}
.account--input {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(229, 231, 235, 1);
  border-radius: 4px;
  height: 52px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  margin-top: 5px;
  padding-left: 12px;
  padding-right: 12px;
  transition: border-color 150ms ease-in-out 0s;
}
.account--button {
  position: relative;
  background-color: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  -webkit-box-align: stretch;
  height: 45px;
  width: 100%;
  font-size: 18px;
  margin-top: 22px;
  margin-bottom: 5px;
  border-radius: 4px;
  text-decoration: none;
  cursor: not-allowed;
  z-index: 0;
  /* appearance: none; */
  transition: all 150ms ease-in-out 0s;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}
.account--button.isPasswordValid,
.account--button.isEmailValid {
  cursor: pointer;
  background-color: rgba(209, 213, 219, 1);
}
.account--button.isPasswordValid:hover,
.account--button.isEmailValid:hover {
  opacity: 0.5;
}
/* mini */
@media only screen and (min-width: 981px) {
  .account {
    display: flex;
    flex-direction: row;
    padding: 1rem;
  }
  .account--content {
    /* margin: 0 auto; */
    width: 50%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
</style>