<template>
	<div class="model--wrapper">
		<div>
			<h2 class="page--title"><u>Models</u></h2>
		</div>
		<div v-if="!!models.length">
      <ul style="text-align: left; list-style-type: none;">
        <li class="model" :class="{ selected: selected(model) }" style="text-transform: capitalize;" v-for="(model) in models" :key="model.id" @click="setModel(model)">
          <div class="model--list"><span class="name">{{model.title}}</span><span v-if="selected(model)" class="icon">&#10003;</span></div>
        </li>
      </ul>
		</div>
		<div v-else>
			<div class="model" >Click Designer button to add model.</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Model",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["models", "model", "keys"]),
  },
  created() {
    this.$store.dispatch("getModels");
  },
  methods: {
    ...mapActions(["getModel", "getRecord", "deleteModel", "setKeys"]),
    selected(selected) {
      if (this.model !== undefined) return selected.id === this.model.id;
    },
    async deleteModel(model) {
      await this.deleteModel(model.id);
    },
    async setModel(model) {
      // console.log(model);
      // let keys = [];
      // for (let item of model.items) {
      //   keys.push(item.name);
      // }
      // await this.setKeys(keys);
      await this.getModel(model.id);
      await this.getRecord(model.id);
    },
  },
};
</script>

<style scoped>
/* model */
.model--wrapper {
  padding: 0 0.5rem;
}
.page--title {
  text-align: center;
  margin: 10px 0 10px 0;
}
.model {
  padding: 10px 5px;
  margin: 10px auto;
  width: 100%;
  min-width: 275px;
  height: auto;
  cursor: pointer;
  background-color: rgb(229, 231, 235, 1);
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
.model:hover {
  background-color: rgba(209, 213, 219, 1);
}
.model.selected {
  color: #000;
  background-color: #078c50;
}
.model--list {
  display: flex;
}
.model--list .name {
  flex: auto;
}
.model--list .icon {
  padding-right: 1rem;
}
.form--content {
  padding: 5px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}

/* Wider screen */
@media screen and (min-width: 981px) {
  .form--content {
    padding: 10px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
      rgb(60 64 67 / 15%) 0px 1px 3px 1px;
  }
}
</style>