<template>
	<div>
		<div class="backdrop" :class="{active: isSlide}"></div>
		<div class="dashboard">
			<aside class="aside--left" :class="{active: isSlide}">
				<div class="aside--container">
					<div class="aside--logo">
						<svg class="logo--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
							<path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z"></path>
						</svg>
						<router-link class="nav--link" style="flex: auto;" to="/"><span class="logo--text">Algoforms</span></router-link>
						<button type="button" class="slider" ref="slider" @click="slide">&#10005;</button>
					</div>
					<div class="aside--wrapper">
						<div class="aside--actions">
							<button class="aside--action aside--item" :class="{'active': pages.isModel}" @click="enablePage('isModel')">
								<svg class="item--icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"></path>
								</svg>
								<span class="item--text">Models</span>
							</button>
							<button class="aside--action aside--item" :class="{'active': pages.isInsight}" @click="enablePage('isInsight')">
								<svg class="item--icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
								</svg>
								<span class="item--text">Insights</span>
							</button>
							<button class="aside--action aside--item" :class="{'active': pages.isForm}" @click="enablePage('isForm')">
								<svg class="item--icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
								</svg>
								<span class="item--text">Forms</span>
							</button>
							<button class="aside--action aside--item" :class="{'active': pages.isDesigner}" @click="enablePage('isDesigner')">
								<svg class="item--icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z"></path>
								</svg>
								<span class="item--text">Designer</span>
							</button>
							<button class="aside--action aside--item" :class="{'active': pages.isPreview}" @click="enablePage('isPreview')">
								<svg class="item--icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
								</svg>
								<span class="item--text">Preview</span>
							</button>
							<button class="aside--action aside--item" :class="{'active': pages.isUser}" @click="enablePage('isUser')">
								<svg class="item--icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path>
								</svg>
								<span class="item--text">Users</span>
							</button>
						</div>
						<div class="aside--controls">
							<button class="aside--control aside--item" :class="{'active': pages.isSetting}" @click="enablePage('isSetting')">
								<svg class="item--icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"></path>
								</svg>
								<span class="item--text">Settings</span>
							</button>
							<button class="aside--control aside--item" :class="{'active': pages.isHelp}" @click="enablePage('isHelp')">
								<svg class="item--icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
								</svg>
								<span class="item--text">Help</span>
							</button>
							<button class="aside--control aside--item">
                <svg class="item--icon" viewBox="0 0 24 24" fill="currentColor" style="stroke: none">
                  <path d="M20 8.69V4h-4.69L12 .69 8.69 4H4v4.69L.69 12 4 15.31V20h4.69L12 23.31 15.31 20H20v-4.69L23.31 12 20 8.69zM12 18c-.89 0-1.74-.2-2.5-.55C11.56 16.5 13 14.42 13 12s-1.44-4.5-3.5-5.45C10.26 6.2 11.11 6 12 6c3.31 0 6 2.69 6 6s-2.69 6-6 6z"></path>
                  <path d="M0 0h24v24H0V0z" fill="none"></path>
                </svg>
								<span class="item--text">Theme</span>
							</button>
						</div>
					</div>
					<div class="aside--profile aside--item" :class="{'active': pages.isAccount}" @click="enablePage('isAccount')">
						<svg class="profile--icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
						</svg>
						<span class="profile--text">Account</span>
						<svg class="profile--icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>
						</svg>
					</div>
				</div>
			</aside>
			<main class="main">
				<div class="main--header">
					<button type="button" class="slider"  @click="slide">&#9776;</button>
					<div class="main--search">
						<!--<svg class="search--icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
						<!--	<path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>-->
						<!--</svg>-->
						<input type="search" class="search--input" id="search" name="search" placeholder="Search templates">
						<label for="search">
							<span class="sr-only">Search</span>
							<svg class="search--icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
							</svg>
						</label>
					</div>
				</div>
				<div class="main--body">
					<Insight v-if="pages.isInsight"></Insight>
					<Designer v-if="pages.isDesigner" :isEditing="isEditing" @cancelEdit="cancelEdit"></Designer>
					<Model v-if="pages.isModel"></Model>
					<Preview v-if="pages.isPreview" @setDesigner="setDesigner"></Preview>
					<Forms v-if="pages.isForm"></Forms>
					<User v-if="pages.isUser" @setInsight="setInsight"></User>
					<Account v-if="pages.isAccount"></Account>
					<Help v-if="pages.isHelp"></Help>
					<Setting v-if="pages.isSetting" @setInsight="setInsight"></Setting>
				</div>
			</main>
      <aside class="aside--right">
        <div class="aside--container">
          <div class="aside--info">
            <h1 style="width: 100%; height: 2rem;">Do You Know?</h1>
          </div>
          <div class="aside--wrapper">
            <div class="aside--actions aside--content">
              <p>Algoforms just got better, as we have added a new invite feature to the platform. You can now invite new users to create an algoforms account, and subsequently participate in survey.</p>
              <p>You will be prompted to send invite to users via email whose profiles do not exist on the paltform. We love giving more power and control to users to successfully accomplish their task here.</p>
              <p>Visit the Users tab, to explore this exciting feature.</p>
            </div>
            <div class="aside--actions aside--content">
              <p>Are you a premium user? Premium users have access to an unlimited features on algoforms. Upgrade today and enjoy these amazing features.</p>
            </div>
          </div>
        </div>
      </aside>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Account from "@/components/dashboard/Account.vue";
import Designer from "@/components/dashboard/Designer.vue";
import Forms from "@/components/dashboard/Forms.vue";
import Help from "@/components/dashboard/Help.vue";
import Insight from "@/components/dashboard/Insight.vue";
import Preview from "@/components/dashboard/Preview.vue";
import Setting from "@/components/dashboard/Setting.vue";
import Model from "@/components/dashboard/Model.vue";
import User from "@/components/dashboard/User.vue";
export default {
  name: "Dashboard",
  components: {
    Insight,
    Designer,
    Model,
    Forms,
    User,
    Preview,
    Account,
    Help,
    Setting,
  },
  data() {
    return {
      pages: {
        isInsight: false,
        isDesigner: false,
        isModel: true,
        isPreview: false,
        isForm: false,
        isUser: false,
        isSetting: false,
        isHelp: false,
        isAccount: false,
      },
      isEditing: false,
      isSlide: false,
      windowWidth: 0,
    };
  },
  watch: {
    windowWidth() {
      if (this.windowWidth >= 730) {
        this.isSlide = false;
        // this.$store.dispatch("isOverlay", false);
      }
    },
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("resize", this.getWindowWidth);
      //Init
      this.getWindowWidth();
    });
  },
  methods: {
    setInsight(page) {
      this.enablePage(page);
    },
    setPreview(page) {
      this.enablePage(page);
    },
    setDesigner(page, value) {
      this.isEditing = value;
      this.enablePage(page);
    },
    cancelEdit(value) {
      this.isEditing = value;
    },
    slide() {
      this.isSlide = !this.isSlide;
      // this.$store.dispatch("isOverlay", this.isSlide);
    },
    async enablePage(current) {
      if (this.isSlide) this.isSlide = !this.isSlide;
      // await this.$store.dispatch("isOverlay", this.isSlide);
      if (this.pages[current]) return;
      else {
        for (let page in this.pages) {
          this.pages[page] = page === current ? true : false;
        }
      }
    },
    getWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
  async beforeDestroy() {
    this.isSlide = false;
    // await this.$store.dispatch("isOverlay", this.isSlide);
    window.removeEventListener("resize", this.getWindowWidth);
  },
};
</script>

<style scoped>
/* dashboard */
.dashboard {
  display: flex;
  justify-content: center;
  height: 100vh;
}
.backdrop {
  position: none;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.backdrop.active {
  position: fixed;
}
.slider {
  width: 2rem;
  height: 2rem;
  font-size: 2.5rem;
  font-weight: bolder;
  font-size: x-large;
  padding-right: 0.5rem;
  cursor: pointer;
  background-color: transparent;
}
.page--title {
  text-align: center;
  margin: 10px 0 5px 0;
}
.sr-only {
  display: none;
}
.aside--left,
.main,
.aside--right {
  display: inline-block;
}
.aside--right {
  display: none;
}
.aside--left {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  width: 0%;
  transition: 0.25s all ease-in-out;
  border-right: 1px solid rgba(209, 213, 219, 1);
}
.aside--left.active {
  width: 95%;
  transition: 0.25s all ease-in-out;
}
.aside--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(243, 244, 246, 1);
  color: rgba(55, 65, 81, 1);
  overflow: hidden;
}
.aside--logo,
.aside--info {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;
}
.logo--icon {
  width: 2rem;
  height: 2rem;
  fill: currentColor;
}
.logo--text {
  margin-left: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
}
.main--logo--text {
  line-height: 1.25rem;
  font-weight: 700;
}
.aside--wrapper {
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
/* .aside--actions button,
.aside--controls button {
  cursor: pointer;
} */
.aside--actions,
.aside--controls {
  width: 100%;
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top-width: 1px;
  border-color: rgba(209, 213, 219, 1);
}
.aside--action,
.aside--control {
  width: 100%;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-top: 0.5rem;
  height: 3rem;
  align-items: center;
  display: flex;
  border-radius: 0.25rem;
}
.aside--action:hover,
.aside--control:hover,
.aside--profile:hover {
  background-color: rgba(209, 213, 219, 1);
}
button.aside--item {
  background: transparent;
}
.aside--item {
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  height: 3rem;
  margin-top: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;
  cursor: pointer;
}
.aside--item.active {
  background-color: rgba(209, 213, 219, 1);
}
.item--icon {
  height: 1.5rem;
  width: 1.5rem;
  stroke: currentColor;
}
.item--text {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-left: 0.5rem;
}
.aside--profile {
  width: 100%;
  margin-top: auto;
  border-radius: 0rem;
  height: 4rem;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgba(229, 231, 235, 1);
}
.profile--icon {
  width: 1.5rem;
  height: 1.5rem;
  stroke: currentColor;
}
.profile--text {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
}
.aside--content {
  display: flex;
  align-items: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;
}
.main {
  border-left: 1px solid rgba(209, 213, 219, 1);
  background-color: rgba(243, 244, 246, 1);
  color: rgba(55, 65, 81, 1);
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-y: auto;
}
.main--header {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;
}
.main--header .main--search {
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
}
.search--icon {
  width: 2rem;
  height: 2rem;
  stroke: currentColor;
}
.search--input {
  flex: auto;
  border-bottom-width: 1px;
  border-color: rgba(209, 213, 219, 1);
  border: none;
  line-height: 2;
  padding-left: 0.5rem;
}
.main--body {
  width: 100%;
  margin-top: 0.7rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  border-top-width: 1px;
  border-color: rgba(209, 213, 219, 1);
}
/* mini screen */
@media screen and (min-width: 730px) {
  .aside--left,
  .main .aside--right {
    display: inline-block;
  }
  .aside--left {
    position: relative;
    width: 20% !important;
  }
  .logo--text {
    margin-left: 0.5rem;
    font-size: larger;
    line-height: 1.25rem;
    font-weight: 700;
  }
  .main {
    width: 80%;
  }
  .slider {
    display: none;
  }
}
/* Wider screen */
@media screen and (min-width: 1200px) {
  .aside--left,
  .aside--right {
    position: relative;
    display: block;
    width: 20% !important;
  }
  .aside.aside--right {
    border-left: 1px solid rgba(209, 213, 219, 1);
  }
  .main {
    width: 60% !important;
    border-right: 1px solid rgba(209, 213, 219, 1);
  }
}
</style>