<template>
	<div class="preview--wrapper">
		<div>
			<h1 class="page--title" v-if="Object.keys(model).length === 0"><u>Preview Model</u></h1>
		</div>
		<div class="preview" v-if="Object.keys(model).length !== 0">
      <div class="preview--header">
        <!--<div>&#9965;</div>-->
        <h2 class="page--title">{{model.title}}</h2>
        <!-- <button class="preview--button" @click="setDesigner();">&#9998;</button> -->
      </div>
      <!-- <hr style="border: 1px solid #374151;"> -->
      <hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
      <div v-if="!!validations.length" class="validations">
        <ul style="text-align: left; list-style-type: disc;">
          <li style="text-transform: capitalize;" v-for="(validation, index) in validations" :key="index">{{validation}}</li>
        </ul>
      </div>
      <form class="form" @submit.prevent="setDesigner();">
        <div class="form--item" v-for="(item, index) in items.items" :key="index">
          <label :for="item.name" class="form--label" :class="{required: item.required}">{{item.label}}:</label>
          <select v-if="item.type === 'select'" class="form--input" v-model="item.value" :name="item.name" :id="item.id" @blur="onBlur($event)" :required="item.required" style="width: 100%;">
            <option>--</option>
            <option v-for="(option, counter) in item.options" :key="counter">{{option.name}}</option>
          </select>
          <input v-else :type="item.type" class="form--input" :name="item.name" :id="item.name" v-model="item.value" @blur="onBlur($event)" :placeholder="item.placeholder" :aria-required="item.ariaRequired" :aria-invalid="item.ariaInvalid" :required="item.required">
          <span></span>
        </div>
        <div class="form--item">
          <span>By clicking Save Button, you agree to our <router-link class="anchors" :to="{ name: 'Terms' }"> Terms of Use </router-link> and our <router-link class="anchors" :to="{ name: 'Privacy' }"> Privacy Policy.</router-link></span>
        </div>
        <div class="form--item">
          <button type="submit" class="form--button">&#9998; Edit Model</button>
        </div>
      </form>
		</div>
		<div v-else class="preview" style="padding: 10px;">
			<div>Select a model to continue!</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Preview",
  components: {},
  data() {
    return {
      validations: [],
      items: {},
    };
  },
  computed: {
    ...mapGetters(["models", "model", "records"]),
    item() {
      var items = {};
      items.id = this.items.id;
      items.title = this.items.title;
      this.items.items.filter((item) => {
        items[item.name] = item.value;
      });
      return items;
    },
  },
  mounted() {
    this.items = JSON.parse(JSON.stringify(this.model));
  },
  methods: {
    ...mapActions(["setMessage"]),
    setDesigner() {
      this.$emit("setDesigner", "isDesigner", true);
    },
    onBlur(event) {
      event.target.style.borderColor = event.target.value
        ? "rgba(229,231,235, 1)"
        : "rgba(255, 0, 0, 1)";
    },
    validate() {
      /**
       * Empty Errors container
       * Check If inputs are Empty
       * Clear error messages on the page
       * If No Errors Return True
       */
      this.validations = [];
      for (let item of this.items.items) {
        if (item.required && item.value === "") {
          this.validations.push(`${item.label} is required!`);
        }
      }
      setTimeout(() => (this.validations = []), 5000);
      if (this.validations.length) return false;
      return true;
    },
    submit() {
      if (!this.validate()) return;
      this.setMessage({ text: `Preview successful!`, status: true });
      this.items = JSON.parse(JSON.stringify(this.model));
    },
  },
};
</script>

<style scoped>
/* preview */
.preview--wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.page--title {
  text-align: center;
  flex: auto;
  margin: 10px 0 5px 0;
}
.form--content {
  padding: 10px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
.inner--aside {
  display: none;
}
.preview {
  /* padding: 10px; */
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
.preview--content {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  /*background-color: #ffffff;*/
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
.preview--header {
  display: flex;
  /*justify-content: space-between;*/
}
.preview--header button {
  background-color: rgba(209, 213, 219, 1);
  padding: 0.75rem 1rem;
  border-radius: 0;
  cursor: pointer;
}
.validations {
  display: flex;
  justify-content: center;
  /* background-color: #ff0000; */
  color: #ff0000;
  padding: 0.5rem;
  border-radius: 5px;
}
.required:after {
  color: #ff0000;
  content: "*";
  font-weight: 500;
  padding-left: 0.5rem;
}
.form {
  padding: 3.5rem 0;
  /* max-width: 400px; */
}
.form--item {
  display: flex;
  flex-direction: column;
  border: none;
  margin: 0px 0px 1.5rem;
  padding: 0px 1rem;
}
.form--label {
  color: #000;
  position: relative;
  height: 16px;
  text-align: left;
  font-size: inherit;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.02rem;
}
.form--input {
  font-size: inherit;
  line-height: 3rem;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
  padding-left: 0.75rem;
  margin-top: 5px;
}
.form--button {
  position: relative;
  /* background-color: transparent; */
  cursor: pointer;
  background-color: rgb(209, 213, 219, 1);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  -webkit-box-align: stretch;
  height: 45px;
  width: 100%;
  font-size: 18px;
  margin-top: 48px;
  margin-bottom: 5px;
  border-radius: 4px;
  text-decoration: none;
  /* z-index: 0; */
  transition: all 150ms ease-in-out 0s;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}
.form--button:hover {
  opacity: 0.5;
}
.sr-only {
  display: none;
}
/* Wider screen */
@media screen and (min-width: 981px) {
  .preview--wrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
  .inner--aside {
    display: block;
    width: 25%;
  }
  .preview {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
      rgb(60 64 67 / 15%) 0px 1px 3px 1px;
  }
  .form--content {
    padding: 10px;
    margin-right: auto;
    margin-left: auto;
    width: 60%;
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
      rgb(60 64 67 / 15%) 0px 1px 3px 1px;
  }
}
</style>