<template>
  <div class="setting--wrapper">
    <h2 class="page--title" v-if="Object.keys(model).length === 0"><u>Manage Model</u></h2>
    <div v-if="Object.keys(model).length !== 0" class="setting">
      <div class="setting--header">
        <h2 class="setting--title">{{ model.title? model.title : 'No model seleceted'}}</h2>
        <!-- <button type="button" class="setting--close" @click="setInsight">&#10005;</button> -->
      </div>
      <hr style="border: 1px solid #374151;">
      <div class="setting--body">
        <div class="setting--search">
          <label for="search" class="setting--label">Search Users</label>
          <input type="search" name="search" id="search" v-model="search" @blur="onBlur($event)" placeholder="Search users" class="setting--input" />
        </div>
        <div class="user--content">
          <ul class="user--items" v-if="search">
            <li class="user--list" :class="{selected: selected(user)}" v-for="user in filterUsers" :key="user" @click="selectUser(user);"><span class="name">{{ user }}</span><span v-if="selected(user)" class="icon">&#10003;</span></li>
          </ul>
        </div>
      </div>
      <div class="setting--footer">
        <form>
          <div class="user--role">
            <label for="role" class="setting--label">Assign a role: </label><br>
            <select name="role" id="role" ref="role" class="setting--input" @change="selectRole($event)" :disabled="model.ownerId !== profile.uid">
              <option value="" selected disabled>Select role</option>
              <option v-for="(role) in roles" :value='role.id' :key="role.id">{{ role.name }}</option>
            </select>
          </div>
          <div class="footer--controls">
            <button type="button" @click="setInsight">Cancel &nbsp; &#10007;</button>
            <button type="button"  @click="onUpdateModel">Confirm &nbsp; &#10003;</button>
          </div>
        </form>
      </div>
    </div>
    <div v-else class="setting" style="padding: 10px;">
			<div>Select a model to continue!</div>
		</div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Setting",
  components: {},
  data() {
    return {
      search: "",
      selections: [],
    };
  },
  computed: {
    filterUsers() {
      return this.model.users.filter(
        (user) =>
          user !== this.profile.email &&
          user.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    ...mapGetters(["model", "users", "user", "roles", "profile"]),
  },
  methods: {
    ...mapActions(["setMessage", "updateModel"]),
    onBlur(event) {
      event.target.style.borderColor = event.target.value
        ? "rgba(0,0,0, 1)"
        : "rgba(255, 0, 0, 1)";
    },
    setInsight() {
      /**
       * Emits 'setInsight' to dashboard
       * @param null
       * @return null
       */
      this.$emit("setInsight", "isInsight");
    },
    selected(item) {
      /**
       * Return true if item is already selected
       * @param {object} item
       * @return {boolean}
       */
      return (
        !!this.selections.length &&
        this.selections.some((selection) => selection === item)
      );
    },
    selectUser(user) {
      /**
       * Adds user to selections or otherwise
       * @param {object} user
       * @return null
       */
      if (this.selections.some((selection) => selection === user))
        this.selections.splice(this.selections.indexOf(user), 1);
      else this.selections.push(user);
    },
    selectRole(event) {
      /**
       * Adds role to users in selections or otherwise
       * @param {string} event id
       * @return null
       */
      const role = this.roles.find((role) => role.id === +event.target.value);
      this.selections.filter((selection) => {
        switch (role.id) {
          case 1:
            if (this.model.owners.includes(selection))
              this.model.owners.splice(this.model.owners.indexOf(selection), 1);
            break;

          case 2:
            if (this.model.owners.includes(selection)) return;
            this.model.owners.push(selection);
            break;

          case 3:
            if (this.model.owners.includes(selection)) return;
            this.model.owners.push(selection);
            break;

          default:
            break;
        }
      });
    },
    validate() {
      /**
       * Empty Errors container
       * Check If Input Is Empty
       * Clear error messages on the page
       * Return true if no errors
       */
      this.validations = [];
      if (!this.email) this.validations.push("Email is required!");

      setTimeout(() => (this.validations = []), 5000);

      if (this.validations.length) return false;
      return true;
    },
    async onUpdateModel() {
      /**
       * Validate input
       * Send selected users to to database or otherwise
       * @param null
       * @return null
       */
      if (this.$refs.role.value === "" || !this.selections.length)
        return this.setMessage({
          text: `Incomplete input!`,
          status: false,
        });
      this.updateModel(this.model);
      this.search = "";
      this.selections = [];
      this.$refs.role.value = "";
    },
  },
};
</script>

<style scoped>
/* setting */
.setting--wrapper {
  padding: 1rem;
}
.page--title {
  text-align: center;
  margin: 10px 0 5px 0;
}
.setting {
  /*padding: 10px;*/
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  /* box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px; */
}
.setting--header {
  display: flex;
}
.setting--title {
  text-align: center;
  flex: auto;
  margin: 10px 0 5px 0;
}
.setting--header button {
  background-color: rgba(209, 213, 219, 1);
  font-weight: 900;
  font-size: larger;
  padding: 0.75rem 1rem;
  border-radius: 0;
}
.setting--body {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
  padding: 0 5px;
}
.setting--search {
  text-align: left;
  width: 100%;
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}
/* .setting--search input {
  width: 100%;
  line-height: 2;
  padding-left: 0.5rem;
  border-bottom: 2px solid #374151;
} */
.setting--label {
  font-size: 14px;
  color: rgb(61, 79, 88);
  position: relative;
  height: 16px;
  /*width: 36px;*/
  text-align: left;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.02em;
}
/* .user--email input {
  width: 100%;
  line-height: 2;
  padding-left: 0.5rem;
  border-bottom: 2px solid #374151;
} */
.setting--input {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(229, 231, 235, 1);
  border-radius: 4px;
  height: 52px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  margin-top: 5px;
  padding-left: 12px;
  padding-right: 12px;
  transition: border-color 150ms ease-in-out 0s;
}
.user--content {
  min-height: 400px;
  /*width: 100%;*/
  border: 2px solid #374151;
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
.user--list {
  display: flex;
  list-style-type: none;
  background-color: rgba(229, 231, 235, 1);
  border-bottom: 1px solid rgba(209, 213, 219, 1);
  text-align: left;
  padding-left: 4px;
  cursor: pointer;
}
.user--list:hover {
  list-style-type: none;
  background-color: rgba(209, 213, 219, 1);
  border-bottom: 1px solid rgba(229, 231, 235, 1);
}
/* .user--list.selected {
  background-color: #c3e8d7;
  color: #078c50;
} */
.user--list.selected {
  background-color: #e6cf4d;
  color: #000;
}
.user--list .name {
  flex: auto;
}
.user--list .icon {
  padding-right: 1rem;
}
.setting--footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
  padding: 0 5px;
  padding-bottom: 3rem;
}
.user--role {
  text-align: left;
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}
/* .user--role > select {
  width: 100%;
  padding: 0.3rem;
  border-bottom: 2px solid #374151;
} */
.footer--controls {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}
.footer--controls button {
  padding: 0.2rem 0.5rem;
  background-color: rgba(209, 213, 219, 1);
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
  transition: all 1s ease-out;
}
.footer--controls button:hover {
  background-color: rgba(229, 231, 235, 1);
}
.footer--controls button:first-child {
  margin-right: 1rem !important;
}
.sr-only {
  display: none;
}
/* Wider screen */
@media screen and (min-width: 981px) {
}
</style>