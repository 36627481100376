<template>
	<div class="form--wrapper">
		<div>
			<h2 class="page--title"><u>Forms</u></h2>
		</div>
		<div v-if="!!forms.length">
      <ul style="text-align: left; list-style-type: none;">
        <li class="form" style="text-transform: capitalize;" v-for="(form, index) in forms" :key="index">
          <!-- <router-link class="forms--title" to="/dashboard">Dashboard</router-link> -->
          <div class="forms--list" @click="onGetForm(form)">{{form.title}}</div>
        </li>
      </ul>
		</div>
		<div v-else>
			<div class="form">Click Designer button to add form.</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Forms",
  components: {},
  data() {
    return {
      validations: [],
    };
  },
  computed: {
    ...mapGetters(["forms", "form"]),
  },
  created() {
    this.getForms();
  },
  mounted() {
    // this.items = this.forms[this.forms.length - 1];
  },
  methods: {
    ...mapActions(["getForms", "getForm"]),
    async onGetForm(form) {
      await this.getForm(form.id);
      await this.$router.push({ name: "Form", params: { id: form.id } });
    },
    setPreview() {
      this.$emit("setPreview", "isPreview");
    },
  },
};
</script>

<style scoped>
/* form */
.form--wrapper {
  padding: 0 0.5rem;
}
.page--title {
  text-align: center;
  margin: 10px 0 10px 0;
}
.form {
  padding: 10px 5px;
  margin: 10px auto;
  width: 100%;
  min-width: 275px;
  /*width: 275px;*/
  height: auto;
  cursor: pointer;
  background-color: rgb(229, 231, 235, 1);
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
.form:hover {
  background-color: rgba(209, 213, 219, 1);
}
.form--content {
  padding: 5px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  /*background-color: #ffffff;*/
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}

/* Wider screen */
@media screen and (min-width: 981px) {
  /* .form {
    width: 70%;
    margin: 0 auto;
  } */
  .form--content {
    padding: 10px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    /*background-color: #ffffff;*/
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
      rgb(60 64 67 / 15%) 0px 1px 3px 1px;
  }
}
</style>