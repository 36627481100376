<template>
	<div class="help-wrapper">
    <div>
      <h1 class="page--title">How can we help you?</h1>
    </div>
		<div class="help--search">
      <form class="help--form">
        <div class="form--item">
          <input type="search" name="search" id="search" placeholder="Describe your issue" autocomplete="off" aria-autocomplete="list" aria-expanded="false" aria-label="Suggested Articles" spellcheck="false" aria-invalid="false"/>
        </div>
      </form>
		</div>
    <div>
      <div>
        <h2>Popular topics</h2>
      </div>
      <div class="topic--items">
        <ul class="topic--item">
          <li class="topic--list" v-for="(help, index) in helps" :key="index">
            <div class="topic--title" @click="selected(index);">
              <h3>{{ help.title }}</h3>
              <span v-if="isIndex === index">&minus;</span>
              <span v-else>&plus;</span>
            </div>
            <div class="topic--description" v-if="isIndex === index">
              <ul class="topic--descriptions">
                <li v-for="description in help.descriptions" :key="description">{{ description }}</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="help--or"><span>or</span></div>
    <div>
      <p>If you have not done so already, please read the documentation and here's tips on how to write a <router-link class="record" to="/support">support ticket</router-link></p>
      <p>For the fasted support, please use the form above to send us your support ticket. We are also available on twitter on <a href="https://twitter.com/algoforms">@algoforms</a> and via email <a href="mailto:info@algoforms.com?subject=Algoforms%20help" target="_top">info@algoforms.com</a></p>
    </div>
	</div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Help",
  components: {},
  data() {
    return {
      isIndex: "",
      active: {},
      helps: [
        {
          title: "Algoforms Account",
          descriptions: [
            "Create an Algoforms Account",
            "Create a strong password & a more secure account",
            "Verify your account with link from your email inbox",
          ],
        },
        {
          title: "Login and Password",
          descriptions: [
            "Select sign in from menu bar.",
            "Sign in or sign up using an email and password.",
            "Log in using your credentials.",
          ],
        },
        {
          title: "Account Settings",
          descriptions: [
            "Select account button at the bottom of the page.",
            "Select account information to edit",
            "Edit information",
            "Select 'Save' button to continue.",
          ],
        },
        {
          title: "Privacy and Security",
          descriptions: [
            "Always ensure that you use strong passwords.",
            "Enable login alerts and two-factor authentication.",
            "Log out from a third-party device.",
            "Delete and clear cache and browsing history.",
          ],
        },
      ],
    };
  },
  computed: {},
  methods: {
    selected(index) {
      if (this.isIndex === index) return (this.isIndex = "");
      else this.isIndex = index;
    },
  },
};
</script>

<style scoped>
/* help */
.help--wrapper {
  display: flex;
  padding: 1rem;
}
.page--title {
  text-align: center;
  margin: 10px 0 5px 0;
}
.help--search {
  width: 100%;
}
.help--form {
  width: 100%;
}
.form--item {
  width: 100%;
}
.form--item input {
  /*background-color: #ffffff;*/
  border: none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  color: #202124;
  display: block;
  font-size: 1rem;
  height: 3rem;
  padding: 0 2.5rem 0 4.5rem;
  width: 100%;
  -webkit-appearance: none;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}

.topic--wrapper {
}
.topic--items {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}
.topic--item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  max-width: 100%;
  padding: 5px;
}
.topic--list {
  border: 2px solid rgba(229, 231, 235, 1);
  /*border-radius: .5rem;*/
  margin: 0.5rem 0;
  /*padding: 0 .75rem;*/
  width: 100%;
  list-style-type: none;
  /*list-style: none;*/
  /*min-height: 3.125rem;*/
  /*box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 1px;*/
}
/*.topic--description {*/
/*  display: none;*/
/*}*/
.topic--link {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.topic--title {
  display: flex;
  padding: 0.5rem;
  word-break: break-word;
  color: #050505;
  font-weight: 600;
  font-family: var(--font-family-segoe);
  text-align: left;
  line-height: 1.1765;
  word-wrap: break-word;
  font-size: 1.0625rem;
  /*border: 1px solid rgba(229,231,235, 1);*/
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
.topic--title h3 {
  flex: auto;
  text-align: center;
}
.topic--title span {
  font-size: larger;
  cursor: pointer;
}
.topic--descriptions {
  list-style-type: disc;
  text-align: left;
  list-style-position: inside;
  padding: 0.5rem;
  font-size: small;
}

.help--or {
  margin: 20px 0px;
  position: relative;
  text-align: center;
  width: 298px;
  width: calc(100vw - 32px);
  width: 100%;
}
.help--or::before {
  display: inline-block;
  border-top: 1px solid rgb(184, 196, 194);
  content: "";
  margin: 0px auto;
  position: absolute;
  height: 0px;
  inset: 50% 0px 0px;
  transform: translateY(-50%);
  width: 300px;
}
.help--or span {
  background: rgb(255, 255, 255);
  background-color: rgba(243, 244, 246, 1);
  padding: 0px 10px;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  color: rgb(93, 108, 116);
  text-align: center;
  position: inherit;
}
/* mini screen */
@media screen and (min-width: 481px) {
  .help--form {
    margin: 0 auto;
    padding: 0 0.5rem 2.5rem;
    width: 100%;
  }
  .topic--descriptions {
    font-size: initial;
  }
}
/* max screen */
@media screen and (min-width: 481px) {
  .help--form {
    margin: 0 auto;
    padding: 0 0 2.5rem;
    width: 32.5rem;
  }
}
</style>